// react lib
import React, { Component } from 'react';
// react router
import { Link } from 'react-router-dom';
// svg & icons
import { UserIcon, PasswordIcon, AngleIcon } from '../Svg';
import { isEmail } from '../../utility';
import api from "../../config/env/Urls"

// validation
import {validateEmail, validateMobileNo1} from "../../utils/Validation";

// connect to store
import { connect } from "react-redux"

// bind actions
import { bindActionCreators } from "redux"

// actions 
import { login } from "../../redux/actions/Auth"

// icons 
import { BackGroundImg, ForGroundImg, LogoImg } from "../common/icon/Icon"

// inline error
import InlineError from "../common/InlineError"

// loader
import Loader from "../../components/common/Loader"



class Login extends Component {

    state = {
        email: '',
        password: '',
        emailError: '',
        passwordError: '',
    }





    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            emailError: '',
            passwordError: ''
        })
    }

    handleValidate = () => {
        let { email, password } = this.state;

    }


    // this function handle on submit login button event or action
    handleSubmit = e => {
        e.preventDefault();

        const { email, password } = this.state;
 

      

        if (email == '')
            this.setState({ emailError: '*Please enter email.' })
        else if(!validateEmail(email).status)
            {
                this.setState({ emailError: "*Please enter valid email." })
    
    
            }
        if (password == '')
            this.setState({ passwordError: '*Please enter password.' })
     


        if (email != '' && password != '' && validateEmail(email).status) {
            let req = {
                email: this.state.email,
                password: this.state.password
            }         
             this.props.action.login(req)

        }
    }

    render() {
        if (localStorage.getItem("accessToken") && localStorage.getItem("isLoggedIn"))
            this.props.history.push("dashboard")

        return (
            <>
            <div class="auth_body_wrapper login_body_wrapper" style={{ backgroundImage: `url(${ForGroundImg})` }}>
            {this.props.isLoading &&   <Loader/>}
                   <div className="container">
                       <div className="row">
                          
                           <div className="col-lg-6 order-lg-2 d-flex login_bg" style={{ backgroundImage: `url(${BackGroundImg})` }}>
                        </div>

                        <div className="col-lg-6 order-lg-1 d-flex login_from_bg">
                        <form className="align-self-center cm_login_form" onSubmit={this.handleSubmit}>
                                <div className="login_logo">
                                    <img src={LogoImg} />
                                </div>
                                <h5>Sign In</h5>
                                {/* <p className="logintext">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,</p> */}
                                

                                {/* <div class="field">
                                    <input type="password" required autocomplete="off" id="password1"/>
                                    <label  title="EMAIL" data-title="EMAIL"></label>
                                </div>
                                <div class="field">
                                    <input type="password" required autocomplete="off" id="password"/>
                                    <label for="password" title="Şifrenizi girin" data-title="Şifre"></label>
                                </div> */}
                                
                                <div className="form-group cm_from_group">
                                  <label>EMAIL</label>
                                    <input
                                        type="email"
                                        className={`form-control ${this.state.emailError != '' ? 'is-invalid' : ''}`}
                                        placeholder="Enter email"
                                        name="email"
                                        maxLength={50}
                                        autocomplete="off" 
                                        onChange={this.handleChange}
                                    />
                                    <InlineError
                                        message={this.state.emailError}
                                    />
                                </div>
                                <div className="form-group cm_from_group position_rel">
                                  <label>PASSWORD</label>
                                    <input
                                        type="password"
                                        className={`form-control ${this.state.passwordError != '' ? 'is-invalid' : ''}`}
                                        placeholder="Enter password"
                                        name="password"
                                        value={this.state.password}
                                        maxLength={20}
                                        autocomplete="off" 
                                        onChange={this.handleChange}
                                    />
                                    <Link className="align-self-center forgot_pass" to="/forgot-password">Forgot?</Link>
                                    <InlineError
                                        message={this.state.passwordError}
                                    />
                                </div>
                                <br/>
                                <div className="form-group d-flex justify-content-between float-right">
                                    <button type="submit" className="btn btn-primary">Login</button>
                                </div>

                              
                            </form>
                        </div>
                       </div>
                   </div>
                   <footer className="col-sm-12 fixed_footer">
                    <p className="text-center">©2020 DrawBridge. All Rights Reserved.</p>
                </footer>
               </div>
          



            {/* <div className="auth_body_wrapper" style={{ backgroundImage: `url(${ForGroundImg})` }}>
              {this.props.isLoading &&   <Loader/>}
                <div className="container">
                    <div className="row h-100">

                        <div className="col-lg-6 order-lg-2 d-flex login_background" style={{ backgroundImage: `url(${BackGroundImg})` }}>
                           
                        </div>

                        <div className="col-lg-6 order-lg-1 d-flex">
                            <form className="align-self-center cm_login_form" onSubmit={this.handleSubmit}>
                                <div className="login_logo">
                                    <img src={LogoImg} />
                                </div>
                                <h5>Sign In</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,</p>
                                <div className="form-group cm_from_group">
                                  <label>EMAIL</label>
                                    <input
                                        type="email"
                                        className={`form-control ${this.state.emailError != '' ? 'is-invalid' : ''}`}
                                        placeholder="Enter email or username"
                                        name="email"
                                        maxLength={50}
                                        autocomplete="off" 
                                        onChange={this.handleChange}
                                    />
                                    <InlineError
                                        message={this.state.emailError}
                                    />
                                </div>
                                <div className="form-group cm_from_group position_rel">
                                  <label>PASSWORD</label>
                                    <input
                                        type="password"
                                        className={`form-control ${this.state.passwordError != '' ? 'is-invalid' : ''}`}
                                        placeholder="Enter password"
                                        name="password"
                                        value={this.state.password}
                                        maxLength={20}
                                        autocomplete="off" 
                                        onChange={this.handleChange}
                                    />
                                    <Link className="align-self-center forgot_pass" to="/forgot-password">Forgot?</Link>
                                    <InlineError
                                        message={this.state.passwordError}
                                    />
                                </div>
                                <br/>
                                <div className="form-group d-flex justify-content-between float-right">
                                    <button type="submit" className="btn btn-primary">Login</button>
                                </div>

                              
                            </form>
                        </div>
                        
                    </div>
                </div>
                <footer className="col-sm-12 fixed_footer">
                    <p className="text-center">©2019 DrawBridge. All Rights Reserved.</p>
                </footer>
            </div> */}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ login }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);